import React from "react";
import loadingAnimation from "../../../../media/svg/game-loading.svg";
import {usePreferences} from "../../../../utils/PreferencesProvider";

const GameFinish = () => {
    const { texts } = usePreferences();

    return (
        <div className="game-awaiting-container">
            <img src={loadingAnimation} alt=""/>
            <span>{texts.gameEndAwaiting}</span>
        </div>
    )
}

export default GameFinish;