import React, {useEffect, useState} from "react";
import './index.css';
import DepositChoiceElement from "./DepositChoiceElement";
import axios from "axios";
import cfg from "../../../utils/cfg";
import {useAuthContext} from "../../../utils/useAuthContext";
import {useNavigate} from "react-router-dom";
import btc from './../../../media/svg/payemnts/612712e338dfbb5883e4f9607900a908.svg';
import eth from './../../../media/svg/payemnts/2e48145389fc8bb6eda3272f7958956a.svg';
import usdt from './../../../media/svg/payemnts/f9c7a3037a4d8ba25b89493efa57d229.svg';
import card from './../../../media/svg/payemnts/118cb4f28430e03efd5242dd2e391323.svg';
import gift from './../../../media/svg/payemnts/gift.svg';
import {useUserSocketData} from "../../../utils/useUserSocket";
import {usePreferences} from "../../../utils/PreferencesProvider";

const DefaultInputs = ({depositIsAwaiting}) => {
    const {texts} = usePreferences();
    return(
        <>
            <input id="deposit-amount" required={true} className="deposit-input" type="number" placeholder={`${texts.sumIn} ₽`}/>
            <input className={`deposit-submit`} type="submit" disabled={depositIsAwaiting} value={texts.deposit}/>
        </>
    )
}
const PromoInputs = ({depositIsAwaiting}) => {
    const {texts} = usePreferences();
    return(
        <>
            <input required={true} id="deposit-promo" className="deposit-input" type="text" placeholder={texts.promo}/>
            <input className={`deposit-submit`} type="submit" disabled={depositIsAwaiting} value={texts.activate}/>
        </>
    )
}

const DepositChoice = () => {
    const [activeDepositChoice, setActiveDepositChoice] = useState(null);
    const { depositIsAwaiting, setDepositIsAwaiting } = useUserSocketData();
    const {texts} = usePreferences();
    const [activePromo, setActivePromo] = useState(null);
    const [inputs, setInputs] = useState(<DefaultInputs depositIsAwaiting={depositIsAwaiting} />)
    const depositMapping = {
        'Bank (Russia)': ['card', card],
        'Bank (Ukraine)': ['card_ua', card],
        'Bank (Kazakhstan)': ['card_kz', card],
        'Ethereum': ['eth', eth],
        'USDT': ['usdt', usdt],
        'Bitcoin': ['btc', btc],
        'Promo': ['promo', gift],
    };
    const deposits = Object.keys(depositMapping);
    const [error, setError] = useState(null);
    const { getToken } = useAuthContext();
    const navigate = useNavigate();
    const activeChoiceHandler = (name) => {
        setActiveDepositChoice(name);
    }

    useEffect(() => {
        if (activeDepositChoice !== 'Promo') {
            setInputs(<DefaultInputs depositIsAwaiting={depositIsAwaiting} />);
        } else {
            setInputs(<PromoInputs depositIsAwaiting={depositIsAwaiting} />);
        }
    }, [activeDepositChoice, depositIsAwaiting]);


    const requestActivePromo = async () => {
        await axios.get(`${cfg.apiURL}/promocode`, {
            headers: {
                'auth-token': getToken()
            }
        }).then((response) => {
            if (response.data.length > 0) {
                setActivePromo([response.data[0]['promocode'], response.data[0]['nominal']])
            } else {
                setActivePromo(null);
            }
        }).catch((e) => {
            console.log(e);
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setDepositIsAwaiting(true);
        if (activeDepositChoice !== 'Promo') {
            const amount = Number(document.getElementById('deposit-amount').value);
            axios.post(`${cfg.apiURL}/payment/init`, {
                amount: amount,
                type: depositMapping[activeDepositChoice][0]
            }, {
                headers: {
                    'auth-token': getToken()
                }
            }).then((response) => {
                navigate(`/deposit/${response.data['_id']}`);
            }).catch((error) => {
                console.log(error);
                const errorServer = error?.response?.data?.message || 'An error occurred';
                setError([errorServer || 'An error occurred', 'error'])
            }).finally(() => {
                setDepositIsAwaiting(false);
            })
        } else {
            const promo = document.getElementById('deposit-promo').value;
            axios.post(`${cfg.apiURL}/promocode`, {
                promocode: promo,
            }, {
                headers: {
                    'auth-token': getToken()
                }
            }).then((response) => {
                console.log(response)
                if (response.data.type === 1) {
                    setError([`${texts.yourNextDepositWillBeIncreased} ${response.data.nominal}%`, 'success']);
                } else {
                    setError([`${texts.promoActivated} ${response.data.nominal}₽`, 'success']);
                }
            }).catch((error) => {
                console.log(error)
                setError([texts.promoNotFound, 'error'])
            }).finally(() => {
                setDepositIsAwaiting(false);
            })
        }

    }
    useEffect(() => {
        requestActivePromo();
    }, []);
    return (
        <div className="deposit-choice">
            <div className="deposit-top">
                <span>{texts.deposit}</span>
            </div>
            <div className="deposit-choice-main">
                <div className="deposit-choice-main-left">
                    {deposits.map((deposit) => (
                        <DepositChoiceElement
                            key={deposit}
                            name={deposit}
                            image={depositMapping[deposit][1]}
                            isChecked={deposit === activeDepositChoice}
                            onChange={() => activeChoiceHandler(deposit)}
                        />
                    ))}
                </div>
                <form onSubmit={handleSubmit} className="deposit-choice-main-right">
                    {inputs}
                    {error && <span className={`deposit-error ${error[1]}`}>{error[0]}</span>}
                    {activePromo &&
                        <span className="deposit-form-promo">{texts.promo} {activePromo[0]} {texts.activatedDepositBonus} +{activePromo[1]}%</span>}
                </form>
            </div>
        </div>
    )
}

export default DepositChoice;