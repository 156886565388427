import React from "react";
import './index.css'

const DepositChoiceElement = ({ name, isChecked, onChange, image }) => {
    return (
        <label className="deposit-choice-element">
            <img src={image} alt='img' className='deposit-choice-img'></img>
            <span>{name}</span>
            <input
                id={name}
                className="deposit-choice-checkbox"
                type="checkbox"
                checked={isChecked}
                onChange={onChange}
                style={{display: 'none'}}
            />
            <span className="deposit-choice-percent">0%</span>
        </label>
    )
}


export default DepositChoiceElement;