import React, {useEffect, useState} from "react";
import './index.css'
import {useAuthContext} from "../../../utils/useAuthContext";
import axios from "axios";
import cfg from "../../../utils/cfg";
import card from './../../../media/svg/payemnts/card.svg';
import eth from './../../../media/svg/payemnts/eth.svg';
import btc from './../../../media/svg/payemnts/bitcoin.svg';
import usdt from './../../../media/svg/payemnts/usdt.svg';
import {useNavigate} from "react-router-dom";
import {useUserSocketData} from "../../../utils/useUserSocket";
import useModal from "../../../utils/useModal";
import {usePreferences} from "../../../utils/PreferencesProvider";

const Deposit = ({id}) => {
    const { getToken } = useAuthContext();
    const { close } = useModal();
    const { updateBalance } = useUserSocketData();
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [copyButtonSum1, setCopyButtonSum1] = useState(false);
    const [copyButtonSum2, setCopyButtonSum2] = useState(false);
    const [copyButtonSum3, setCopyButtonSum3] = useState(false);
    const [requestError, setRequestError] = useState(null);
    const [activePromo, setActivePromo] = useState(null);
    const navigate = useNavigate();
    const {texts} = usePreferences();
    const requestPaymentDetails = async (id) => {
        await axios.post(`${cfg.apiURL}/payment`, {
            '_id': id
        }, {
            headers: {
                'auth-token': getToken(),
            },
        }).then((response) => {
            setPaymentDetails(response.data)
            console.log(response.data)
        }).catch((e) => {
            console.log(e)
        })
    }
    const requestActivePromo = async () => {
        await axios.get(`${cfg.apiURL}/promocode`, {
            headers: {
                'auth-token': getToken()
            }
        }).then((response) => {
            if (response.data.length > 0) {
                setActivePromo([response.data[0]['promocode'], response.data[0]['nominal']])
            } else {
                console.log('no promo')
            }
            console.log(response)
        }).catch((e) => {
            console.log(e)
        })
    }

    const payemntMap = {
        'card': [
            texts.cardRu,
            card,
            '₽'
        ],
        'card_ua': [
            texts.cardUa,
            card,
            '₴'
        ],
        'card_kz': [
            texts.cardKz,
            card,
            '₸'
        ],
        'btc': [
            'Bitcoin',
            btc,
            'BTC'
        ],
        'eth': [
            'Ethereum',
            eth,
            'ETH'
        ],
        'usdt': [
            'USDT (TRC-20)',
            usdt,
            'USDT'
        ]
    }

    useEffect(() => {
        requestPaymentDetails(id);
        requestActivePromo();
    }, []);

    const handleCheckPayment = () => {
        axios.put(`${cfg.apiURL}/payment`,{
            '_id': id
        }, {
            headers: {
                'auth-token': getToken(),
            },
        }).then((response) => {
            if (response.data.status) {
                updateBalance();
                close();
                navigate('/');
            } else {
                setRequestError(texts.fundsNotReceived);
            }
        }).catch((error) => {
            setRequestError(texts.error);
            console.log(error.data);
        })

    }

    const handleCopy = (method) => {
        let copyText;
        if (method === 'card-number') {
            copyText = document.getElementById('card-number');
        } else if (method === 'number') {
            copyText = document.getElementById('payment-number')
        } else {
            copyText = document.getElementById('payment-sum')
        }
        navigator.clipboard.writeText(copyText.innerText).then(() => {
            if (method === 'card-number') {
                setCopyButtonSum3(true)
            } else if (method === 'number') {
                setCopyButtonSum1(true)
            } else {
                setCopyButtonSum2(true)            }
        }
        ).catch(e => console.log(e));
    }

    return (
        <div className="deposit-modal">
            <div className="deposit-top">
                <span>{texts.deposit}</span>
            </div>
            {paymentDetails ? (
                <div className="deposit-details">
                    <div className="deposit-details-top">
                        <img src={payemntMap[paymentDetails.type][1]} alt="payemnt logo"/>
                        <span>{payemntMap[paymentDetails.type][0]}</span>
                    </div>
                    <div className="deposit-details-info">
                        <div className="deposit-detail">
                            <span>{texts.sumToPay}:</span>
                            <div className="deposit-detail-value">
                                <span><span id='payment-sum'>{paymentDetails.details['summ_pay']}</span> {payemntMap[paymentDetails.type][2]} </span>
                                <button onClick={() => handleCopy('sum')} className={`copy-button ${copyButtonSum2}`}>{copyButtonSum2 ? texts.copy : texts.copied}</button>
                            </div>
                        </div>
                        {(paymentDetails.details['bank_name'] && paymentDetails.details['bank_name'] !== "0") &&
                            <div className="deposit-detail">
                                <span>{texts.bankForSbp}:</span>
                                <div className="deposit-detail-value">
                                    <span
                                        id='bank-name'>{paymentDetails.details['bank_name']}</span>
                                </div>
                            </div>}
                        {paymentDetails.details['card'] &&
                            <div className="deposit-detail">
                                <span>{texts.cardNumber}:</span>
                                <div className="deposit-detail-value">
                                    <span
                                        id='card-number'>{paymentDetails.details['card']}</span>
                                    <button onClick={() => handleCopy('card-number')}
                                            className={`copy-button ${copyButtonSum3}`}>{copyButtonSum3 ? texts.copy : texts.copied}</button>
                                </div>
                            </div>}
                        {(paymentDetails.details['number'] && paymentDetails.details['number'] !== "0") &&
                            <div className="deposit-detail">
                                <span>{texts.number}{paymentDetails.type === 'card' ? ` ${texts.phone}` : ''}:</span>
                                <div className="deposit-detail-value">
                                    <span
                                        id='payment-number'>{paymentDetails.details['number']}</span>
                                    <button onClick={() => handleCopy('number')}
                                            className={`copy-button ${copyButtonSum1}`}>{copyButtonSum1 ? texts.copy : texts.copied}</button>
                                </div>
                            </div>}
                        <button className="check-payment"
                                onClick={() => handleCheckPayment()}>{texts.checkPayment}
                        </button>
                        {requestError &&
                            <span className="deposit-error-check">{requestError}</span>}
                        {activePromo &&
                            <span className="deposit-promo">{texts.youEnteredPromo} {activePromo[0]}, {texts.depositBonus} +{activePromo[1]}%</span> }
                    </div>

                </div>
            ) : (
                <span>Not found payment with ID: {id}</span>
            )}
        </div>
    )
}

export default Deposit;