import React from "react";
import './index.css';

const Landing = () => {
    return (
        <div>
            landing
        </div>
    )
}

export default Landing;