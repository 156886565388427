import React, { useState, createContext } from 'react';

export const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalBody, setModalBody] = useState(null);
    const [withID, setWithID] = useState(false);
    const open = (component, id = null) => {
        id ? setWithID(true) : setWithID(false);
        setModalBody(component);
        setIsOpen(true);
    };

    const close = () => {
        setIsOpen(false);
    };

    return (
        <ModalContext.Provider value={{ isOpen, open, close, modalBody, withID }}>
            {children}
        </ModalContext.Provider>
    );
};
