import {Route, Routes} from "react-router-dom";
import Landing from "./components/Landing";
import NotFound from "./components/NotFound";
import Header from "./components/Header";
import Modals from "./components/Modals";
import Main from "./components/Main";
import OpenPaymentModal from "./components/MainWithPayment";
import NotificationsGeneral from "./components/NotificationsGeneral";
function App() {

    return (
        <>
            <Modals />
            <Header />
                <Routes>
                    <Route path="/" element={<Main />} />
                    <Route path="/landing" element={<Landing />} />
                    <Route path="/deposit/:id" element={<OpenPaymentModal />} />
                    <Route path="*" element={<NotFound />} />
                </Routes>
        </>
    );
}

export default App;
