import React from "react";
import './index.css';

const TopElement = ({name, multiplier, bet}) => {
    return (
        <div className="top-element">
            <div className="pfp">
                {name[0]}
            </div>
            <span className="name">{name}</span>
            <span className="bet">{bet.toFixed(1)}₽</span>
            <div className="multiplier">
                <span>
                    {multiplier.toFixed(1)}x
                </span>
            </div>
            <span className="win">{(bet * multiplier).toFixed(1)}₽</span>
        </div>
    )
}

export default TopElement;