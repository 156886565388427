import React from "react";
import './index.css';

const GameValueElement = ({id, value}) => {
    const thresholds = [
        { limit: 1, className: 'default' },
        { limit: 2, className: 'second' },
        { limit: 5, className: 'third' },
        { limit: 10, className: 'forth' },
    ];

    let className = 'default';
    for (let i = 0; i < thresholds.length; i++) {
        if (value > thresholds[i].limit) {
            className = thresholds[i].className;
        } else {
            break;
        }
    }

    return (
        <div className={`game-value-element ${className}`}>
            <span>#{id}</span>
            <span>{value}x</span>
        </div>
    )
}

export default GameValueElement;