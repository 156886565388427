import React, {useContext, useEffect, useRef, useState} from 'react';
import p5 from 'p5';
import './index.css';
import guy from './../../media/png/guy.png'
import fire from './../../media/svg/fire.svg'
import light from './../../media/png/sun.png'
import {MultCContext} from "../../utils/useGraphData";
import {useSound} from "../../utils/useSounds";
import {usePreferences} from "../../utils/PreferencesProvider";

const Graph = ({xValue}) => {
    const { multC, setMultC } = useContext(MultCContext);
    const { playSound } = useSound();
    const { texts } = usePreferences();
    const [gameFinished, setGameFinished] = useState(false);
    const sketchRef = useRef();
    const parentRef = useRef();
    const boxRef = useRef();
    const targetMult = xValue;
    let isEnd = 0;
    useEffect(() => {
        let x = 0;
        let mult = 0;
        let offsetX = 0;
        let offsetY = 0;
        const sketch = (p) => {
            const parentWidth = parentRef.current.offsetWidth;
            const parentHeight = parentRef.current.offsetHeight;

            p.setup = () => {
                p.createCanvas(parentWidth, parentHeight);
                p.frameRate(30);
            };

            p.draw = () => {
                p.background('#141029');
                mult = p.exp(x / 1000);

                if (mult < targetMult) {
                    x += 1.6;
                } else {
                    if (isEnd === 0) {
                        playSound('fly');
                    }
                    isEnd++
                    setGameFinished(true)
                    setMultC(xValue)
                }
                offsetX = p.max(0, x - p.width + 200);

                const lastXValue = p.map(x, 0, p.width, 0, 2);
                const lastYValue = p.exp(lastXValue * 1.15);
                const lastY = p.map(lastYValue, 0.35, 10, p.height, 0);

                offsetY = p.max(0, p.height - lastY - 250);

                const boxX =  x - offsetX;
                const boxY =  lastY + offsetY;

                if (boxRef.current && boxRef.current.style) {
                    boxRef.current.style.left = `${boxX}px`;
                    boxRef.current.style.top = `${boxY}px`;
                }

                p.push();
                p.translate(-offsetX, offsetY);

                drawEXP(x);
                REscale();

                p.pop();
                p.noStroke();
                setMultC(mult.toFixed(2))
                // p.fill('#9250f5');
                // p.textSize(125);
                // p.textAlign(p.CENTER, p.CENTER);
                // p.text(mult.toFixed(2) + "x", p.width / 4, p.height / 4 + 20);
            };

            const drawEXP = (x) => {
                p.stroke('#5e28fa');
                p.strokeWeight(4);
                p.noFill();
                p.beginShape();

                for (let i = 0; i < x; i++) {
                    let xValue = p.map(i, 0, p.width, 0, 2);
                    let yValue = p.exp(xValue * 1.15);
                    let y = p.map(yValue, 0.35, 10, p.height, 0);

                    p.vertex(i, y);
                }
                p.endShape();
            };

            const REscale = () => {
                for (let a = 0; a < (p.width - 20) / 25; a += 5) {
                    p.textAlign(p.CENTER);
                    p.textSize(10);
                    p.noStroke();
                    p.fill(150);
                    p.text(a, (a * p.width / (x / 100)) < 280 ? -15 : a * p.width / (x / 100), p.height - 8);
                }
            };
        };

        const myp5 = new p5(sketch, sketchRef.current);

        return () => {
            myp5.remove();
        };
    }, [xValue]);

    return (
        <div className="graph-container" ref={parentRef} style={{width: '100%', height: '100%'}}>
            <div ref={sketchRef}></div>
            <div className="stars"></div>
            {/*<img src={moon} className="graph-moon" alt=""/>*/}
            <div className="graph-clouds"></div>
            <div className="mult-text-container">
                <span className="mult-display">{multC}x</span>
                <span className={`mult-flew ${gameFinished ? 'visible' : 'hidden'}`}>{texts.flew}</span>
            </div>
            <div ref={boxRef} className={`box ${gameFinished ? 'flew' : ''}`} style={{position: 'absolute'}}>
                <div className="box-wrapper">
                    <img src={guy} alt="guy"/>
                    <img src={fire} alt="fire"/>
                    <img src={light} alt="light"/>
                </div>
            </div>
        </div>
    );
};

export default Graph;