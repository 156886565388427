import React, {useEffect, useState} from "react";
import './index.css';
import {useAuthContext} from "../../../utils/useAuthContext";
import {Link} from "react-router-dom";
import useModal from "../../../utils/useModal";
import Limits from "../../Modals/Limits";
import Trust from "../../Modals/Trust";
import {usePreferences} from "../../../utils/PreferencesProvider";
import Language from "../../Modals/Language";

const Menu = ({headerModalUpdate, setHeaderModalUpdate}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { open } = useModal();
    const { user, logOut } = useAuthContext();
    const { texts } = usePreferences();

    useEffect(() => {
        headerModalUpdate !== 'menu' ? setModalOpen(false) : setModalOpen(true);
    }, [headerModalUpdate]);

    const handleMenu = () => {
        setHeaderModalUpdate('menu')
        setModalOpen(!modalOpen)
    }
    return (
        <div className="header-menu-container">
            <button onClick={handleMenu} className={`header-button menu ${modalOpen ? 'active' : ''}`}>
                <i className="icon menu"></i>
            </button>
            <div className={`header-menu-absolute ${modalOpen ? 'open' : 'closed'}`}>
                <div className="header-menu-top">
                    {user ? (
                        <>
                            <div className="header-user-data">
                                <div className="header-user-pfp">{user.login[0]}</div>
                                <span>{user.login}</span>
                            </div>
                        </>
                    ) : (
                        <div className="header-user-data">
                            <div className="header-user-pfp">D</div>
                            <span>Demo</span>
                        </div>
                    )}
                </div>
                <div className="header-menu-main">
                    <button className="heaer-menu-link" onClick={() => open(<Limits />)}>
                        <i className="icon limits"></i>
                        <span>{texts.gameLimits}</span>
                    </button>
                    <button className="heaer-menu-link" onClick={() => open(<Trust />)}>
                        <i className="icon trust"></i>
                        <span>{texts.guarantees}</span>
                    </button>
                    <button className="heaer-menu-link" onClick={() => open(<Language />)}>
                        <i className="icon question"></i>
                        <span>{texts.language}</span>
                    </button>
                    <Link to='https://t.me/LuckyJet_helper' className="heaer-menu-link">
                        <i className="icon support"></i>
                        <span>{texts.support}</span>
                    </Link>
                </div>
                {user && <button className='header-menu-top-logout'
                                 onClick={logOut}>{texts.logOut}</button>}
            </div>
        </div>
    )
}

export default Menu;