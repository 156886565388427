import React, {useContext, useEffect, useState} from "react";
import './index.css';
import {useUserSocketData} from "../../../utils/useUserSocket";
import {useAuthContext} from "../../../utils/useAuthContext";
import {MultCContext} from "../../../utils/useGraphData";
import cfg from "../../../utils/cfg";
import axios from "axios";
import {useNotification} from "../../../utils/NotificationProvider";
import {usePreferences} from "../../../utils/PreferencesProvider";

const BetButton = () => {
    const [betted, setBetted] = useState(false);
    const [buttonClickable, setButtonClickable] = useState(false);
    const [autoWithdrawal, setAutoWithdrawal] = useState(false);
    const [autoBet, setAutoBet] = useState(false);
    const [targetMultiplier, setTargetMultiplier] = useState(1.5);
    const [betID, setBetID] = useState(0);
    const [betSum, setBetSum] = useState(null);
    const { gameState, updateBalance, isFlying, gameMultTarget, setForceRefreshFunc } = useUserSocketData();
    const { getToken } = useAuthContext()
    const { multC } = useContext(MultCContext);
    const { texts } = usePreferences();
    const random = (Math.random() + 1).toString(36).substring(7);

    const { addNotification } = useNotification();


    const handleInputChange = (event) => {
        setTargetMultiplier(Number(event.target.value));
    };
    const handleCheckboxChange = (event) => {
        setAutoWithdrawal(event.target.checked);
    };
    const handleAutoBetChange = (event) => {
        setAutoBet(event.target.checked);
    };

    const bet = (betAmount) => {
        axios.post(`${cfg.apiURL}/bet`, { amount: Number(betAmount) }, {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': getToken()
            }
        })
            .then(response => {
                setBetSum(betAmount);
                setBetID(response.data['_id']);
                setBetted(true);
                updateBalance();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        setButtonClickable(!isFlying)
    }, [isFlying]);

    const betDone = (multiplier, id) => {
        console.log('multiplier', multiplier);
        axios.put(`${cfg.apiURL}/bet`, { multiplier: Number(multiplier), _id: id }, {
            headers: {
                'Content-Type': 'application/json',
                'auth-token': getToken()
            }
        })
            .then(response => {
                setForceRefreshFunc();
                console.log("bet done", response);
                setBetted(false);
                setButtonClickable(false);
                setBetSum(null);
                addNotification(
                    {
                        'amount': gameState.state === 1 ? document.querySelector(`#element-sum-${random}`).value : response.data.amount,
                        'multiplier': gameState.state === 1 ? 1 : response.data.multiplier,
                        'win': gameState.state === 1 ? true : response.data.multiplier
                    },
                    'bet-picked'
                );
                updateBalance();
            })
            .catch(error => {
                console.error('Error:', error);
            });
    }

    useEffect(() => {
        if ( gameState.state === 2  && autoWithdrawal && betted && multC === targetMultiplier.toFixed(2)) {
            betDone(multC, betID);
        }
        if (multC >= gameMultTarget && gameState.state === 2) {
            setButtonClickable(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [multC])

    useEffect(() => {
        if (gameState.state === 1) {
            setButtonClickable(true);
        } else {
            setButtonClickable(false);
        }

        if (gameState.state === 1 && autoBet) {
            const betAmount = document.querySelector(`#element-sum-${random}`).value;
            bet(betAmount);
        }
        if(gameState.state === 1 && !autoBet) {
            setBetted(false)
        }
        if (gameState.state === 2 && betted) {
            setButtonClickable(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gameState.state]);

    const handleBet = () => {
        console.log(`handle bet ${betted}`)
        if(!betted) {
            const betAmount = document.querySelector(`#element-sum-${random}`).value;
            bet(betAmount);
        } else {
            betDone(gameState.state === 1 ? 1 : multC, betID);
        }
    }

    const handleIncrement = () => {
        const input = document.querySelector(`#element-sum-${random}`);
        input.value = Math.max(0, parseInt(input.value) + 10);
    };

    const handleDecrement = () => {
        const input = document.querySelector(`#element-sum-${random}`);
        input.value = Math.max(0, parseInt(input.value) - 10);
    };

    const handleSetSum = (value) => {
        const input = document.querySelector(`#element-sum-${random}`);
        input.value = value;
    };

    return (
        <div className="button-element">
            <div className="button-element-top">
                <div className="button-checkboxes">
                    <label className="button-checkbox" htmlFor={`auto-bet-${random}`}>
                        <span className="button-checkbox-label">{texts.autoBet}</span>
                        <input id={`auto-bet-${random}`}
                               type="checkbox"
                               onChange={handleAutoBetChange}
                               disabled={!buttonClickable}
                        />
                        <span className="checkmark"></span>
                    </label>
                    <label className="button-checkbox" htmlFor={`auto-withdrawal-${random}`}>
                        <span className="button-checkbox-label">{texts.autoWithdrawal}</span>
                        <input id={`auto-withdrawal-${random}`}
                               type="checkbox"
                               onChange={handleCheckboxChange}
                               disabled={!buttonClickable}
                        />
                        <span className="checkmark"></span>
                    </label>
                </div>
                <div className="input-x">
                    <span>x</span>
                    <input
                        type="number"
                        defaultValue={1.5}
                        onChange={handleInputChange}
                    />
                </div>
            </div>
            <div className="button-element-bottom">
                <div className="button-element-bottom-left">
                    <div className="button-element-sum">
                        <button onClick={handleDecrement}>
                            <span>-</span>
                        </button>
                        <input type="number" id={`element-sum-${random}`} defaultValue={100} />
                        <button onClick={handleIncrement}>
                            <span>+</span>
                        </button>
                    </div>
                    <div className="button-element-sum-buttons">
                        <button onClick={() => handleSetSum(10)}>10₽</button>
                        <button onClick={() => handleSetSum(30)}>30₽</button>
                        <button onClick={() => handleSetSum(50)}>50₽</button>
                        <button onClick={() => handleSetSum(100)}>100₽</button>
                    </div>
                </div>
                <div className="button-element-bottom-right">
                    <button onClick={handleBet} className={betted ? 'betted' : ''} disabled={!buttonClickable}>
                        {betted && <span>{(multC * betSum).toFixed(1)}₽</span>}
                        <span>
                            {betted ? texts.take : texts.bet}
                        </span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default BetButton;