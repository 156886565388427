import React, { useEffect } from 'react';
import './index.css';
import {usePreferences} from "../../utils/PreferencesProvider";

const Notification = ({ message, type, onClose }) => {
    const {texts} = usePreferences();
    useEffect(() => {
        const timer = setTimeout(onClose, 5000);
        return () => clearTimeout(timer);
    }, [onClose]);

    if (type === "general") {
        return (
            <div className="regular-notification">
                {message}
            </div>
        )
    }

    return (
        <div className={`notification ${message['win'] ? 'won' : 'lost'}`}>
            <div className="notification-left">
                <span>{message['win'] ? texts.pickedWin : texts.pickedLoss}</span>
                <span>x{message['multiplier']}</span>
            </div>
            <button onClick={onClose} className="notification-right">
                <span>{message['win'] ? (message['amount'] * message['multiplier']).toFixed(1) : message['amount']} ₽</span>
                <span>{message['win'] ? texts.youWon : texts.youLost}</span>
            </button>
        </div>
    );
};

export default Notification;