import React, {createContext, useEffect, useState} from 'react';
import {useUserSocketData} from "./useUserSocket";

export const MultCContext = createContext();

export const MultCProvider = ({ children }) => {
    const {gameState} = useUserSocketData();
    const [multC, setMultC] = useState(1);
    useEffect(() => {
        if (gameState.state === 1) {
            setMultC(1);
        }
    }, [gameState.state]);
    return (
        <MultCContext.Provider value={{ multC, setMultC }}>
            {children}
        </MultCContext.Provider>
    );
};
