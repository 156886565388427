import React, {useState} from "react";
import './index.css';
import All from "./leftComponents/All";
import {useAuthContext} from "../../utils/useAuthContext";
import useModal from "../../utils/useModal";
import Login from "../Modals/Login";
import MainArea from "./MainArea";
import ValuesHistory from "./ValuesHistory";
import BetButton from "./BetButton";
import {MultCProvider} from "../../utils/useGraphData";
import My from "./leftComponents/My";
import Top from "./leftComponents/Top";
import {usePreferences} from "../../utils/PreferencesProvider";
const Main = () => {
    const [activeLeftTab, setActiveLeftTab] = useState('all');
    const [activeLeftComponent, setActiveLeftComponent] = useState(<All />);
    const { open } = useModal();
    const { user } = useAuthContext();
    const { texts } = usePreferences();

    const leftTabHandler = (element) => {
        switch (element) {
            case 'all': setActiveLeftTab('all'); setActiveLeftComponent(<All />); break;
            case 'my':  setActiveLeftTab('my'); setActiveLeftComponent(<My />); break;
            case 'top': setActiveLeftTab('top'); setActiveLeftComponent(<Top />); break;
            default:    setActiveLeftTab('all'); setActiveLeftComponent(<All />); break;
        }
    }

    return (
        <MultCProvider>
            <main className="main-app">
                <div className="main-right">
                    <div className="matches-list">
                        <ValuesHistory/>
                    </div>
                    <MainArea/>
                    <div className="bet-controls">
                        <BetButton/>
                        <BetButton/>
                    </div>
                </div>
                <div className="main-left">
                    <div className="left-controls">
                        <button
                            className={`left-control-button ${activeLeftTab === 'all' ? 'active' : ''}`}
                            onClick={() => leftTabHandler('all')}>{texts.all}
                        </button>
                        {user ? (
                            <button
                                className={`left-control-button ${activeLeftTab === 'my' ? 'active' : ''}`}
                                onClick={() => leftTabHandler('my')}>{texts.my}</button>
                        ) : (
                            <button
                                className={`left-control-button ${activeLeftTab === 'my' ? 'active' : ''}`}
                                onClick={() => open(<Login/>)}>{texts.my}</button>
                        )}
                        <button
                            className={`left-control-button ${activeLeftTab === 'top' ? 'active' : ''}`}
                            onClick={() => leftTabHandler('top')}>{texts.top}
                        </button>
                    </div>
                    <div className="left-users-container">
                        {activeLeftComponent}
                    </div>
                </div>
            </main>
        </MultCProvider>
    )
}

export default Main;