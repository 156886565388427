import React, {createContext, useContext, useEffect, useState} from 'react';
import NotificationContainer from "../components/NotificationsContainer";
import {languageData} from "./languageData";

const PreferencesContext = createContext();

export const usePreferences = () => useContext(PreferencesContext);

export const PreferencesProvider = ({ children }) => {
    const [language, setLanguage] = useState(() => {
        return localStorage.getItem('appLanguage') || 'RU';
    });

    useEffect(() => {
        localStorage.setItem('appLanguage', language);
    }, [language]);

    const changeLanguage = (newLanguage) => {
        setLanguage(newLanguage);
    };

    return (
        <PreferencesContext.Provider value={{ language, changeLanguage, texts: languageData[language] }}>
            {children}
        </PreferencesContext.Provider>
    );
};
