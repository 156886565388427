import React from "react";
import loadingAnimation from './../../../../media/svg/game-loading.svg'
import {usePreferences} from "../../../../utils/PreferencesProvider";

const RoundWaiting = () => {
    const { texts } = usePreferences();

    return (
        <div className="game-awaiting-container">
            <img src={loadingAnimation} alt=""/>
            <span>{texts.gameStartAwaiting}</span>
            <div className="timeline-container">
                <div className="timeline-inner"></div>
            </div>
        </div>
    )
}

export default RoundWaiting;