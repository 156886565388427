import React, {useEffect, useState} from "react";
import './index.css';
import htp1 from './../../../media/png/htp1.png'
import htp2 from './../../../media/png/htp2.png'
import htp3 from './../../../media/png/htp3.png'
import {usePreferences} from "../../../utils/PreferencesProvider";
const HowToPlay = ({headerModalUpdate, setHeaderModalUpdate}) => {
    const [modalOpen, setModalOpen] = useState(false);
    const { texts } = usePreferences();

    useEffect(() => {
        headerModalUpdate !== 'htp' ? setModalOpen(false) : setModalOpen(true);
    }, [headerModalUpdate]);

    const modalHandler = () => {
        setHeaderModalUpdate('htp')
        setModalOpen(!modalOpen)
    }

    return(
        <div className="how-to-play-container">
            <button onClick={modalHandler} className={`header-button how-to-${modalOpen ? 'active' : 'inactive'}`}>
                <i className="icon question"></i>
                <span>{texts.htp}?</span>
            </button>
            <div className={`how-to-play-absolute ${modalOpen ? 'opened' : 'closed'}`}>
                <div className="how-to-element">
                    <img src={htp1} alt="htp"/>
                    <span>{texts.htp1}</span>
                </div>
                <div className="how-to-element">
                    <img src={htp2} alt="htp"/>
                    <span>{texts.htp2}</span>
                </div>
                <div className="how-to-element">
                    <img src={htp3} alt="htp"/>
                    <span>{texts.htp3}</span>
                </div>
            </div>
        </div>
    )
}

export default HowToPlay