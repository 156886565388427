import React, { createContext, useState, useEffect, useContext } from 'react';
import socketIOClient from "socket.io-client";
import cfg from "./cfg";
import axios from "axios";
import { useAuthContext } from "./useAuthContext";
import {useNotification} from "./NotificationProvider";

const UserSocketDataContext = createContext();

export const UserSocketDataProvider = ({ children }) => {
    const { getToken } = useAuthContext();
    const [isFlying, setIsFlying] = useState(false);
    const [gameMultTarget, setGameMultTarget] = useState(1);
    const [games, setGames] = useState([]);
    const [bets, setBets] = useState([{"count":0,"seeds": []}])
    const [userBets, setUserBets] = useState([{"count":0,"seeds": []}])
    const [gameState, setGameState] = useState({"state":1,"id":0});
    const [notifications, setNotifications] = useState([]);
    const [currentGameID, setCurrentGameID] = useState(0);
    const [userBalance, setUserBalance] = useState(0);
    const [displayedBets, setDisplayedBets] = useState([]);
    const [depositIsAwaiting, setDepositIsAwaiting] = useState(false)
    const [forceRefresh, setForceRefresh] = useState(Date.now());

    const token = getToken() || 'no-user';
    const { addNotification } = useNotification();

    const setForceRefreshFunc = () => {
        setForceRefresh(Date.now());
    }

    useEffect(() => {
        setDisplayedBets([]);
        if (bets["seeds"]) {
            bets["seeds"].forEach((bet) => {
                setTimeout(() => {
                    setDisplayedBets((prevDisplayedBets) => [
                        ...prevDisplayedBets,
                        bet,
                    ]);
                }, Math.floor(Math.random() * (15000 - 1000 + 1) + 1000));
            });
        }
    }, [bets]);


    const fetchGames = async () => {
        try {
            const response = await axios.get(`${cfg.defaultURL}/api/game`, {
                headers: {
                    'auth-token': getToken()
                }
            });
            if (response.status === 200) {
                setGames(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const fetchBets = async () => {
        try {
            const response = await axios.get(`${cfg.defaultURL}/api/bet`, {});
            if (response.status === 200) {
                setBets(response.data);
            }
        } catch (error) {
            console.log(error);
        }
    };
    const fetchUserBets = async () => {
        try {
            const response = await axios.get(`${cfg.defaultURL}/api/bet`, {
                headers: {
                    'auth-token': getToken()
                }
            });
            if (response.status === 200) {
                setUserBets(response.data.reverse());
            }
        } catch (error) {
            console.log(error);
        }
    };

    const updateBalance = async () => {
        if (token !== 'no-user') {
            try {
                const response = await axios.post(`${cfg.userURL}/isAuth`, {token});
                setUserBalance(response.data.balance.toFixed(1) || 0);
            } catch (e) {
                console.log(e)
            }
        } else {
            setUserBalance(0);
        }
    }

    useEffect(() => {
        fetchUserBets();
        fetchBets();
    }, [currentGameID, token]);

    useEffect(() => {
        updateBalance();
    }, [gameState.state, forceRefresh]);

    useEffect(() => {
        fetchGames();
        let isOnLoadCount = false;
        const socket = socketIOClient(cfg.socketURL, {
            query: { token: token }
        });

        // console.log(`Socket Checking: ${getToken() || 'no-user'}`)

        socket.emit('state');

        socket.on('connect', () => {
            console.log('Connected to server socket');
            isOnLoadCount = false;
        });

        socket.on("state", (state) => {
            setCurrentGameID(state.id);
            if (state.state === 2) {
                setGameMultTarget(state.multiplier)
            }
            setIsFlying(false);
            if (!isOnLoadCount) {
                isOnLoadCount = true;
                setGameState({"state":3,"id":state.id});
            } else {
                setGameState(state);
            }
        });

        socket.on("betPlayed", (bet) => {
            setIsFlying(true);
            console.log(bet);
            if(!bet.win && !bet.active) {
                addNotification(
                    {
                        'amount': bet.amount,
                        'multiplier': 0,
                        'win': bet.win
                    },
                    'bet-picked'
                );
            }
        });

        socket.on("gameFinished", (game) => {
            // {"multiplier": value, "id": value}
            setGames(prev => [...prev, game]);
        });

        socket.on("notification", (notification) => {
            console.log(notification);
            addNotification(notification.text, "general");
        })

        return () => socket.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);

    return (
        <UserSocketDataContext.Provider value={{ games, gameState, bets, userBets, notifications, currentGameID, userBalance, updateBalance, fetchUserBets, displayedBets, isFlying, depositIsAwaiting, setDepositIsAwaiting, setForceRefreshFunc, gameMultTarget }}>
            {children}
        </UserSocketDataContext.Provider>
    );
};

export const useUserSocketData = () => useContext(UserSocketDataContext);