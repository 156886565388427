import React from "react";
import './index.css';

const MyBetElement = ({amount, gameId, multiplier, isWin, username, isActive}) => {
    const thresholds = [
        { limit: 1, className: 'default' },
        { limit: 2, className: 'second' },
        { limit: 5, className: 'third' },
        { limit: 10, className: 'forth' },
    ];
    let className = 'default';
    for (let i = 0; i < thresholds.length; i++) {
        if (multiplier > thresholds[i].limit) {
            className = thresholds[i].className;
        } else {
            break;
        }
    }

    return (
        <div className={`my-bet-element ${isWin ? 'won' : 'lost'} ${isActive ? 'isActive' : ''}`}>
            <div className="my-pfp">
                <span>{username[0]}</span>
            </div>
            <span className="my-game-id">#{gameId}</span>
            <span className={`my-word ${isWin ? 'won' : 'lost'}`}>{amount}₽</span>
            <div className={`my-multiplier ${className} ${isWin ? 'won' : 'lost'}`}>
                <span>{!isActive ? `${multiplier}x` : '-'}</span>
            </div>
            <span className={`my-result ${isWin ? 'won' : 'lost'}`}>{!isActive ? (isWin ? `+${(amount * multiplier).toFixed(1)}₽` : `-${amount}₽`) : '-'}</span>
        </div>
    )
}

export default MyBetElement;