import React, {useContext, useEffect, useState} from "react";
import './index.css';
import {useUserSocketData} from "../../../../utils/useUserSocket";
import {MultCContext} from "../../../../utils/useGraphData";

const UserElement = ({ pfp, username, initSum, multiplier, winSum }) => {
    const { gameState } = useUserSocketData();
    const { multC } = useContext(MultCContext);
    const [isPicked, setIsPicked] = useState(false);
    const [seedBetData, setSeedBetData] = useState(
        ['-', '-']
    );
    const thresholds = [
        { limit: 1, className: 'default' },
        { limit: 2, className: 'second' },
        { limit: 5, className: 'third' },
        { limit: 10, className: 'forth' },
    ];

    useEffect(() => {
        if (multC >= multiplier) {
            setIsPicked(true);
            setSeedBetData([`${multiplier}x`, `${winSum}₽`])
        }
    }, [multC]);

    let className = 'default';
    for (let i = 0; i < thresholds.length; i++) {
        if (multiplier > thresholds[i].limit) {
            className = thresholds[i].className;
        } else {
            break;
        }
    }

    return (
        <div className={`user-element ${isPicked ? 'picked' : 'not-picked'}`}>
            <div className="user-pfp">
                {pfp ? pfp[0] : 'U'}
            </div>
            <span className="user-username">{username}</span>
            <span className="user-init-sum">{initSum}₽</span>
            <div className={`user-multiplier ${className}`}>
                <span>{seedBetData[0]}</span>
            </div>
            <span className="user-win-sum">{seedBetData[1]}</span>
        </div>
    )
}

export default UserElement;