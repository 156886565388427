import React, {useState} from "react";
import './../DepositChoice/index.css';
import {useAuthContext} from "../../../utils/useAuthContext";
import axios from "axios";
import cfg from "../../../utils/cfg";
import DepositChoiceElement from "../DepositChoice/DepositChoiceElement";
import btc from './../../../media/svg/payemnts/612712e338dfbb5883e4f9607900a908.svg';
import eth from './../../../media/svg/payemnts/2e48145389fc8bb6eda3272f7958956a.svg';
import usdt from './../../../media/svg/payemnts/f9c7a3037a4d8ba25b89493efa57d229.svg';
import card from './../../../media/svg/payemnts/118cb4f28430e03efd5242dd2e391323.svg';
import {usePreferences} from "../../../utils/PreferencesProvider";

const Withdrawal = () => {
    const [activeDepositChoice, setActiveDepositChoice] = useState(null);
    const depositMapping = {
        'Bank (Russia)': ['card', card],
        'Bank (Ukraine)': ['card_ua', card],
        'Bank (Kazakhstan)': ['card_kz', card],
        'Ethereum': ['eth', eth],
        'USDT': ['usdt', usdt],
        'Bitcoin': ['btc', btc],
    };
    const deposits = Object.keys(depositMapping);
    const [message, setMessage] = useState(null);
    const { getToken } = useAuthContext();
    const {texts} = usePreferences();
    const responseMap = {
        'error': [texts.withdrawalError, 'error'],
        'success': [texts.withdrawalSuccess, 'success'],
        'verification': [texts.withdrawalVerification, 'warn'],
    }
    const activeChoiceHandler = (name) => {
        setActiveDepositChoice(name);
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const amount = Number(document.getElementById('deposit-amount').value);
        const recipient = document.getElementById('recipient').value;
        axios.post(`${cfg.apiURL}/payout`, {
            amount: amount,
            recipient: recipient,
            type: depositMapping[activeDepositChoice][0]
        }, {
            headers: {
                'auth-token': getToken()
            }
        }).then((response) => {
            console.log(response);
            setMessage(responseMap[response.data.type]);
        }).catch((error) => {
            const displayedError = error.response?.data.message || 'An error occurred';
            setMessage([displayedError, 'error'])
            console.log(error)
        })
    }

    return (
        <div className="deposit-choice">
            <div className="deposit-top">
                <span>{texts.withdrawal}</span>
            </div>
            <div className="deposit-choice-main">
                <div className="deposit-choice-main-left">
                    {deposits.map((deposit) => (
                        <DepositChoiceElement
                            key={deposit}
                            name={deposit}
                            image={depositMapping[deposit][1]}
                            isChecked={deposit === activeDepositChoice}
                            onChange={() => activeChoiceHandler(deposit)}
                        />
                    ))}
                </div>
                <form onSubmit={handleSubmit} className="deposit-choice-main-right">
                    <input id="deposit-amount" required={true} className="deposit-input" type="number" placeholder={`${texts.sumIn} ₽`}/>
                    <input id="recipient" required={true} className="deposit-input" type="text" placeholder={texts.withdrawalNumber}/>
                    <input className="deposit-submit" type="submit" value={texts.withdrawal}/>
                    {message && <span className={`deposit-error ${message[1]}`}>{message[0]}</span>}
                </form>
            </div>
        </div>
    )
}

export default Withdrawal;